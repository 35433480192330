import anime from "animejs/lib/anime.es";

const DURATION = 5000;
// const EASING = "linear";
const EASING = "cubicBezier(0.420, 0.000, 0.580, 1.000)";

export default class Animation {
  constructor(options) {
    this.onBegin = (typeof(options.onBegin) == "function") ? options.onBegin : () => {};
    this.onComplete = (typeof(options.onComplete) == "function") ? options.onComplete : () => {};

		this.currentTransition = 0;
		this.countTransition = 0;
    this.transitions = [];
		this.currentTimeline = null;
		this.isAnimated = false;
  }

  addTransition(nextTransition, prevTransition) {
		let that = this;

		nextTransition = (nextTransition) ? nextTransition : null;
		prevTransition = (prevTransition) ? prevTransition : nextTransition;

    that.transitions.push({
			next: (nextTransition) ? nextTransition : null,
			prev: (prevTransition) ? prevTransition : null
		});
		that.countTransition++;
  }

	addToTimeline(points, duration, easing) {
		let that = this;
		duration = duration || DURATION;
		easing = easing || EASING;
		let timeline = that.getTimeline(duration, easing);

		for (let point in points)
			timeline.add(points[point][0], points[point][1]);

		return timeline;
	}

	getTimeline(duration, easing) {
		let that = this;
		return anime.timeline({
      duration: duration,
      easing: easing,
			autoplay: false,
      begin: () => that.animationBegin(),
      complete: () => that.animationComplete()
    });
	}

	animationBegin() {
		let that = this;

		that.onBegin(that.currentTransition);
	}

	animationComplete() {
		let that = this;

		that.isAnimated = false;
		that.onComplete(that.currentTransition);
	}

	transitionNext() {
		if (this.currentTransition >= this.countTransition - 1 || this.isAnimated) return false;
		let that = this;

    that.currentTransition++;
		that.transitionChange(that.transitions[that.currentTransition].next);
	}

	transitionPrev() {
		if (this.currentTransition <= 0 || this.isAnimated) return false;
		let that = this;

    that.currentTransition--;
		that.transitionChange(that.transitions[that.currentTransition].prev);
	}

	transitionChange(transition) {
		let that = this;
		that.isAnimated = true;

		that.currentTimeline = that.addToTimeline(transition.timeline, transition.duration,	transition.easing);
		that.currentTimeline.play();
	}
}
