import Animation from "./../Animation";

export function addAnimations(App, onBegin, onComplete) {
	let Animations = new Animation({onBegin: onBegin, onComplete: onComplete});

	let entities = App.entities;
	let camera = App.camera;

	let timelineNext;
	let timelinePrev;

	Animations.addTransition(null, {timeline: timelinePrev, duration: 2500});
	//++ SLIDE 1 (приезд машины на сцену)
	timelineNext = [];
	timelinePrev = [];
	timelineNext.push([{targets: entities.machine.position, z: 30, easing: "easeOutElastic(1, .8)" }, 0]);
	timelineNext.push([{targets: entities.wheel1.rotation, x: entities.wheel1.rotation.x - Math.PI * 6, easing: "easeOutElastic(1, .8)" }, 0]);
	timelineNext.push([{targets: entities.wheel2.rotation, x: entities.wheel2.rotation.x - Math.PI * 6, easing: "easeOutElastic(1, .8)" }, 0]);
	timelineNext.push([{targets: entities.wheel3.rotation, x: entities.wheel3.rotation.x - Math.PI * 6, easing: "easeOutElastic(1, .8)" }, 0]);
	timelineNext.push([{targets: entities.wheel4.rotation, x: entities.wheel4.rotation.x - Math.PI * 6, easing: "easeOutElastic(1, .8)" }, 0]);
	timelineNext.push([{targets: entities.Cylinder114.material, opacity: 1, duration: 1000}, 500]);
	timelineNext.push([{targets: document.querySelector(".app-outer__background"), opacity: 1, duration: 1000}, 500]);
	timelineNext = addRemoveTransparent(timelineNext, entities);

	timelinePrev.push([{targets: entities.camera.rotation.rotation, y: Math.PI / 2, duration: 2000, easing: "cubicBezier(0.250, 0.100, 0.250, 1.000)" }, 0]);
	timelinePrev.push([{targets: entities.camera.position.position, x: 0, duration: 2000 }, 0]);
	timelinePrev.push([{targets: entities.camera.rotation.scale, x: 1, y: 1, z: 1, duration: 2000 }, 0]);
	timelinePrev = addRemoveTransparent(timelinePrev, entities);

	Animations.addTransition({timeline: timelineNext, duration: 4000}, {timeline: timelinePrev, duration: 2700});
	//++ SLIDE 2 (показ двигателя)
	timelineNext = [];
	timelinePrev = [];
	// timelineNext.push([{targets: entities.camera.rotation.rotation, y: Math.PI * 2 + 0.42, duration: 2000, easing: "cubicBezier(0.250, 0.100, 0.250, 1.000)" }, 0]);
	timelineNext.push([{targets: entities.camera.rotation.rotation, y: Math.PI * 1.5 + 0.50, duration: 2000, easing: "cubicBezier(0.250, 0.100, 0.250, 1.000)" }, 0]);
	timelineNext.push([{targets: entities.camera.position.position, x: 100, duration: 2000 }, 0]);
	timelineNext.push([{targets: entities.camera.rotation.scale, x: 0.5, y: 0.5, z: 0.5, duration: 2000 }, 0]);
	timelineNext = addMakeTransparent(timelineNext, entities, 1000, 1700, 2);
	timelineNext = addEmissiveObject(timelineNext, entities, 2700, 2);

	timelinePrev.push([{targets: entities.camera.rotation.rotation, y: Math.PI * 1.5 + 0.50, duration: 2000, easing: "cubicBezier(0.250, 0.100, 0.250, 1.000)" }, 0]);
	timelinePrev.push([{targets: entities.camera.position.position, x: 100, duration: 2000 }, 0]);
	timelinePrev.push([{targets: entities.camera.rotation.scale, x: 0.5, y: 0.5, z: 0.5, duration: 2000 }, 0]);
	timelinePrev = addRemoveTransparent(timelinePrev, entities);
	timelinePrev = addMakeTransparent(timelinePrev, entities, 1000, 1700, 2);
	timelinePrev = addEmissiveObject(timelinePrev, entities, 2700, 2);

	Animations.addTransition({timeline: timelineNext, duration: 2700}, {timeline: timelinePrev, duration: 3700});
	//++ SLIDE 3 (показ бампера)
	timelineNext = [];
	timelinePrev = [];

	timelineNext.push([{targets: entities.camera.rotation.scale, x: 0.95, y: 0.95, z: 0.95, duration: 1000 }, 0]);
	timelineNext.push([{targets: entities.camera.rotation.rotation, y: Math.PI * 3 - 0.58, duration: 2500, easing: "cubicBezier(0.250, 0.100, 0.250, 1.000)" }, 500]);
	timelineNext.push([{targets: entities.camera.position.position, x: 30, duration: 2500 }, 500]);
	timelineNext = addRemoveTransparent(timelineNext, entities);
	timelineNext = addMakeTransparent(timelineNext, entities, 1000, 2700, 4);
	timelineNext = addEmissiveObject(timelineNext, entities, 3200, 4);

	timelinePrev.push([{targets: entities.Cylinder114.material, opacity: 1, duration: 1000}, 0]);
	timelinePrev.push([{targets: entities.camera.rotation.rotation, y: Math.PI * 3 - 0.58, duration: 2000 }, 1000]);
	timelinePrev.push([{targets: entities.camera.position.position, x: 30, duration: 2000 }, 1000]);
	timelinePrev.push([{targets: entities.camera.position.rotation, x: 0.13, duration: 2000 }, 1000]);
	timelinePrev = addRemoveTransparent(timelinePrev, entities);
	timelinePrev = addMakeTransparent(timelinePrev, entities, 1000, 1700, 4);
	timelinePrev = addEmissiveObject(timelinePrev, entities, 2700, 4);

	Animations.addTransition({timeline: timelineNext, duration: 3700}, {timeline: timelinePrev, duration: 2700});
	//++ SLIDE 4 (показ колес)
	timelineNext = [];
	timelinePrev = [];
	timelineNext.push([{targets: entities.camera.rotation.rotation, y: Math.PI * 2.5 + 0.028, duration: 2000 }, 0]);
	timelineNext.push([{targets: entities.camera.position.position, x: 0, duration: 2000 }, 0]);
	timelineNext.push([{targets: entities.camera.position.rotation, x: -0.9, duration: 2000 }, 0]);
	timelineNext = addRemoveTransparent(timelineNext, entities);
	timelineNext = addMakeTransparent(timelineNext, entities, 1000, 1700, 1);
	timelineNext = addEmissiveObject(timelineNext, entities, 2700, 1);
	timelineNext.push([{targets: entities.Cylinder114.material, opacity: entities.Cylinder114.material.opacity, duration: 1000}, 1700]);

	timelinePrev.push([{targets: entities.camera.rotation.rotation, y: Math.PI * 2.5 + 0.028 }, 0]);
	timelinePrev.push([{targets: entities.camera.rotation.scale, x: 0.95, y: 0.95, z: 0.95 }, 0]);
	timelinePrev.push([{targets: entities.camera.position.position, x: 0 }, 0]);
	timelinePrev.push([{targets: entities.camera.position.rotation, x: -0.9 }, 0]);
	timelinePrev.push([{targets: entities.machine.position, z: 30 }, 400]);
	timelinePrev.push([{targets: entities.Cylinder114.material, opacity: entities.Cylinder114.material.opacity}, 0]);
	timelinePrev = addMakeTransparent(timelinePrev, entities, 1000, 1000, 1);
	timelinePrev = addEmissiveObject(timelinePrev, entities, 2000, 1);


	Animations.addTransition({timeline: timelineNext, duration: 2700}, {timeline: timelinePrev, duration: 2000});
	//SLIDE 5 (машина целиком)
	timelineNext = [];
	timelinePrev = [];
	timelineNext.push([{targets: entities.camera.rotation.rotation, y: Math.PI * 4 }, 0]);
	timelineNext.push([{targets: entities.camera.rotation.scale, x: 1.12, y: 1.12, z: 1.12 }, 0]);
	timelineNext.push([{targets: entities.camera.position.position, x: -50 }, 0]);
	timelineNext.push([{targets: entities.camera.position.rotation, x: 0.13 }, 0]);
	timelineNext.push([{targets: entities.machine.position, z: 0 }, 400]);
	// timelineNext.push([{targets: entities.wheel1.rotation, x: entities.wheel1.rotation.x - Math.PI * 6 }, 0]);
	// timelineNext.push([{targets: entities.wheel2.rotation, x: entities.wheel2.rotation.x - Math.PI * 6 }, 0]);
	// timelineNext.push([{targets: entities.wheel3.rotation, x: entities.wheel3.rotation.x - Math.PI * 6 }, 0]);
	// timelineNext.push([{targets: entities.wheel4.rotation, x: entities.wheel4.rotation.x - Math.PI * 6 }, 0]);
	timelineNext.push([{targets: entities.Cylinder114.material, opacity: 1}, 0]);

	timelineNext = addRemoveTransparent(timelineNext, entities);

	Animations.addTransition({timeline: timelineNext, duration: 2000}, null);
	//++ SLIDE 6 (уезд машины со сцены)
	timelineNext = [];
	timelinePrev = [];
	timelineNext.push([{targets: entities.machine.position, z: 80, duration: 600, easing: "easeOutQuad" }, 0]);
	timelineNext.push([{targets: entities.machine.position, z: -2200 }, 1000]);
	timelineNext.push([{targets: entities.wheel1.rotation, x: entities.wheel1.rotation.x + Math.PI * 2, duration: 600, easing: "easeOutQuad" }, 0]);
	timelineNext.push([{targets: entities.wheel2.rotation, x: entities.wheel2.rotation.x + Math.PI * 2, duration: 600, easing: "easeOutQuad" }, 0]);
	timelineNext.push([{targets: entities.wheel3.rotation, x: entities.wheel3.rotation.x + Math.PI * 2, duration: 600, easing: "easeOutQuad" }, 0]);
	timelineNext.push([{targets: entities.wheel4.rotation, x: entities.wheel4.rotation.x + Math.PI * 2, duration: 600, easing: "easeOutQuad" }, 0]);
	timelineNext.push([{targets: entities.wheel1.rotation, x: entities.wheel1.rotation.x - Math.PI * 18 }, 600]);
	timelineNext.push([{targets: entities.wheel2.rotation, x: entities.wheel2.rotation.x - Math.PI * 18 }, 600]);
	timelineNext.push([{targets: entities.wheel3.rotation, x: entities.wheel3.rotation.x - Math.PI * 18 }, 600]);
	timelineNext.push([{targets: entities.wheel4.rotation, x: entities.wheel4.rotation.x - Math.PI * 18 }, 600]);
	timelineNext.push([{targets: entities.camera.rotation.scale, x: 1, y: 1, z: 1 }, 600]);
	timelineNext.push([{targets: entities.Cylinder114.material, opacity: 0, duration: 500}, 1000]);
	// timelineNext = addMakeTransparent(timelineNext, entities, 1000, 800, 0, 0);

	Animations.addTransition({timeline: timelineNext, duration: 1600}, null);

	//RETURN
	return Animations;
}

function addRemoveTransparent(timeline, entities) {
	let duration = 500;
	let delay = 0;
	let opacity = 1;

	return setTransparent(timeline, entities, duration, delay, opacity);
}

function addMakeTransparent(timeline, entities, duration, delay, notElement, opacity) {
	delay = delay || 0;
	duration = duration || 1000;
	opacity = (opacity == 0) ? opacity : opacity || 0.25;

	return setTransparent(timeline, entities, duration, delay, opacity, notElement);
}

function setTransparent(timeline, entities, duration, delay, opacity, notElement) {
	notElement = notElement || 0;
	if(notElement != 1) entities.wheels.material.forEach((item, i) => {timeline.push([{targets: item, opacity: opacity, duration: duration }, delay]);});
	if(notElement != 2) timeline.push([{targets: entities.engine.material, opacity: opacity, duration: duration }, delay]);
	if(notElement != 3) timeline.push([{targets: entities.car.material, opacity: opacity, duration: duration }, delay]);
	if(notElement != 4) timeline.push([{targets: entities.bumper.material, opacity: opacity, duration: duration }, delay]);

	return timeline;
}

function addEmissiveObject(timeline, entities, delay, indexElement) {
	let maxEmissive = 0.2;
	let duration = 500;

	indexElement = indexElement || 0;
	delay = delay || 0;
	indexElement = indexElement || 0;

	if(indexElement == 1){
		entities.wheels.material.forEach((item, i) => {timeline.push([{targets: item, duration: duration, easing: "easeInSine", emissiveIntensity: maxEmissive }, delay]);});
		entities.wheels.material.forEach((item, i) => {timeline.push([{targets: item, duration: duration, easing: "easeOutSine", emissiveIntensity: 0 }, delay + duration]);});
	}
	if(indexElement == 2){
		timeline.push([{targets: entities.engine.material, duration: duration, easing: "easeInSine", emissiveIntensity: maxEmissive }, delay]);
		timeline.push([{targets: entities.engine.material, duration: duration, easing: "easeOutSine", emissiveIntensity: 0 }, delay + duration]);
	}
	if(indexElement == 3){
		timeline.push([{targets: entities.car.material, duration: duration, easing: "easeInSine", emissiveIntensity: maxEmissive }, delay]);
		timeline.push([{targets: entities.car.material, duration: duration, easing: "easeOutSine", emissiveIntensity: 0 }, delay + duration]);
	}
	if(indexElement == 4){
		timeline.push([{targets: entities.bumper.material, duration: duration, easing: "easeInSine", emissiveIntensity: maxEmissive }, delay]);
		timeline.push([{targets: entities.bumper.material, duration: duration, easing: "easeOutSine", emissiveIntensity: 0 }, delay + duration]);
	}

	return timeline;
}
