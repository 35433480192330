import Loader from "./../Loader";

const MOBILE = /iphone|ipod|ipad|android|blackberry|opera mini|opera mobi|skyfire|maemo|windows phone|palm|iemobile|symbian|symbianos|fennec/i.test(navigator.userAgent.toLowerCase());

let scripts = document.getElementsByTagName("script");
let src = scripts[scripts.length-1].src;
let folder = src.slice(0, src.lastIndexOf('/'));
let assets = folder.slice(0, folder.lastIndexOf('/') + 1);

process.env.ASSETS_URL = assets;

export function addAssets(loaded, progress) {
  let Assets = new Loader({onLoad: loaded, onProgress: progress});

	let assetsFolder = `${process.env.ASSETS_URL}`;

	Assets.addFile("fbx",         "F1",               assetsFolder + "scene/F1.fbx");

	Assets.addFile("texture",     "body_color",       assetsFolder + "textures/body/color.jpg");
	Assets.addFile("texture",     "body_ao",          assetsFolder + "textures/body/ao.jpg");
	Assets.addFile("texture",     "body_bump",        assetsFolder + "textures/body/bump.jpg");
	Assets.addFile("texture",     "body_metal" ,      assetsFolder + "textures/body/metal.jpg");
	Assets.addFile("texture",     "body_roughness" ,  assetsFolder + "textures/body/roughness.jpg");

	Assets.addFile("texture",     "engine_color",     assetsFolder + "textures/engine/color.png");
	Assets.addFile("texture",     "engine_ao",        assetsFolder + "textures/engine/ao.png");
	Assets.addFile("texture",     "engine_height",    assetsFolder + "textures/engine/height.png");
	Assets.addFile("texture",     "engine_metal",     assetsFolder + "textures/engine/metal.png");
	Assets.addFile("texture",     "engine_normal",    assetsFolder + "textures/engine/normal.png");
	Assets.addFile("texture",     "engine_roughness", assetsFolder + "textures/engine/roughness.png");

	Assets.addFile("texture",     "wheels_color",     assetsFolder + "textures/wheels/color.jpg");
	Assets.addFile("texture",     "wheels_ao",        assetsFolder + "textures/wheels/ao.jpg");
	Assets.addFile("texture",     "wheels_bump",      assetsFolder + "textures/wheels/bump.jpg");
	Assets.addFile("texture",     "wheels_metal",     assetsFolder + "textures/wheels/metal.jpg");

	// Assets.addFile("texture",     "sphere_color",     "../textures/sphere/color.png");
	// Assets.addFile("texture",     "sphere_metal",     "../textures/sphere/metal.png");

	Assets.addFile("texture",     "playground_color", assetsFolder + "textures/playground/color.jpg");
	Assets.addFile("texture",     "playground_metal", assetsFolder + "textures/playground/metal.jpg");
	Assets.addFile("texture",     "playground_alpha", assetsFolder + "textures/playground/alpha.jpg");

	Assets.addFile("environment", "environment",      assetsFolder + "textures/environment.jpg");
	//Assets.addFile("texture",     "background",       "../textures/background.jpg");
  Assets.load();

  return {objects: Assets.objects, textures: Assets.textures, other: Assets.other};
}
