import { LoadingManager, TextureLoader, CubeTextureLoader, sRGBEncoding, EquirectangularReflectionMapping } from "three/build/three.module";

import { OBJLoader } from "three/examples/jsm/loaders/OBJLoader";
import { FBXLoader } from "three/examples/jsm/loaders/FBXLoader";
import { TGALoader } from "three/examples/jsm/loaders/TGALoader";

export default class Loader {
  constructor(options) {
    this.onLoad = (typeof(options.onLoad) == "function") ? options.onLoad : () => {};
    this.onProgress = (typeof(options.onProgress) == "function") ? options.onProgress : () => {};

    this.manager = new LoadingManager();
    this.manager.onLoad = this.loaded.bind(this);
    this.manager.onProgress = this.progress.bind(this);
    this.manager.onError = this.error;

    this.files = [];
    this.objects = [];
    this.textures = [];
    this.other = [];
  }

  loaded() {
    this.onLoad();
  }

  progress(url, itemsLoaded, itemsTotal) {
		this.onProgress(url, itemsLoaded, itemsTotal);
  }

  error(url) {
    console.log("There was an error loading " + url);
  }

  addFile(type, name, path) {
    this.files.push({type: type, name: name, path: path});
  }

	load() {
	  let that = this;

	  for (let file in that.files) {

	    let name = that.files[file].name;
	    let path = that.files[file].path;

	    switch(that.files[file].type) {
	      case "object": that.loadObject(name, path); break;
	      case "texture": that.loadTexture(name, path); break;
	      case "fbx": that.loadFBX(name, path); break;
	      case "tga": that.loadTGA(name, path); break;
	      case "cube": that.loadCube(name, path); break;
	      case "environment": that.loadEnvironment(name, path); break;
	    }
	  }
	}

	loadObject(name, path) {
	  let that = this;
	  new OBJLoader(that.manager).load(path, (output) => {
	    output.traverse((child) => {
	      if(child.isMesh) that.objects[name] = child;
	    });
	  });
	}

	loadTexture(name, path) {
	  let that = this;
	  new TextureLoader(that.manager).load(path, (output) => {
	    that.textures[name] = output;
	  });
	}

	loadFBX(name, path) {
		let that = this;
    new FBXLoader(that.manager).load(path, (output) => {
      output.traverse((child) => {
        if(child.isMesh) that.objects[child.name] = child;
        if(child.isGroup) that.other[child.name] = child;
      });
    });
	}

	loadTGA(name, path) {
		let that = this;
    new TGALoader(that.manager).load(path, (output) => {
      that.textures[name] = output;
    });
	}

	loadCube(name, path) {
    let that = this;
    new CubeTextureLoader(that.manager).setPath(path).load(["px.jpg", "nx.jpg", "py.jpg", "ny.jpg", "pz.jpg", "nz.jpg"], (output) => {
      that.textures[name] = output;
    });
  }

	loadEnvironment(name, path) {
    let that = this;
    new TextureLoader(that.manager).load(path, (output) => {
			output.encoding = sRGBEncoding;
			output.mapping = EquirectangularReflectionMapping;
      that.textures[name] = output;
    });
  }
}
