import { MeshStandardMaterial, MeshPhysicalMaterial, MeshPhongMaterial, Object3D, Color, DoubleSide } from "three/build/three.module";
import { SceneUtils } from "three/examples/jsm/utils/SceneUtils";

export function setAssets(App) {
  let objects = App.assets.objects;
	let textures = App.assets.textures;
	let emissiveColor = new Color("#ffffff");
	//ENGINE
	objects.engine.material = new MeshStandardMaterial({
		map: textures.engine_color,
		aoMap: textures.engine_ao,
		normalMap: textures.engine_normal,
		roughnessMap: textures.engine_roughness,
		metalnessMap: textures.engine_metal,
		emissive: emissiveColor,
		emissiveIntensity: 0.0,
		side: DoubleSide,
		// displacementMap: textures.engine_height,
		roughness: 0.7,
		metalness: 0.8,
		transparent: true,
  });
	//WHEELS
	let wheelOuterMaterial = new MeshPhongMaterial({
		map: textures.wheels_color,
		aoMap: textures.wheels_ao,
		bumpMap : textures.wheels_bump,
		emissive: emissiveColor,
		emissiveIntensity: 0.0,
		transparent: true,
  });
	let wheelInnerMaterial = new MeshStandardMaterial({
		map: textures.wheels_color,
		aoMap: textures.wheels_ao,
		bumpMap: textures.wheels_bump,
		metalnessMap: textures.wheels_metal,
		metalness: 1.0,
		roughness: 0.2,
		emissive: emissiveColor,
		emissiveIntensity: 0.0,
		transparent: true,
  });
	let wheelMaterial = [wheelOuterMaterial, wheelInnerMaterial];

	objects.wheels = [];
	objects.wheels.material = wheelMaterial;
	objects.wheel1.material = wheelMaterial;
	objects.wheel2.material = wheelMaterial;
	objects.wheel3.material = wheelMaterial;
	objects.wheel4.material = wheelMaterial;

	//CAR
	let carMaterial = new MeshStandardMaterial({
		map: textures.body_color,
		aoMap: textures.body_ao,
		bumpMap: textures.body_bump,
		metalnessMap: textures.body_metal,
		roughnessMap : textures.body_roughness,
		emissive: emissiveColor,
		emissiveIntensity: 0.0,
		metalness: 1.0,
		roughness: 0.8,
		transparent: true
  });

	objects.car.material = carMaterial.clone();
	objects.bumper.material = carMaterial.clone();

	objects.Cylinder114.material = new MeshPhysicalMaterial({
		map: textures.playground_color,
		metalnessMap : textures.playground_metal,
		alphaMap: textures.playground_alpha,
		metalness: 0.0,
		roughness: 0.1,
		reflectivity: 1.0,
		emissive: emissiveColor,
		emissiveIntensity: 0.0,
		transparent: true,
  });

	return objects;
}
