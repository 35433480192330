import { Object3D, Fog } from "three/build/three.module";

export function setScene(scene, entities, camera) {
	entities.engine.renderOrder = 2;
	entities.car.renderOrder = 3;
	entities.bumper.renderOrder = 3;
	entities.wheel1.renderOrder = 4;
	entities.wheel2.renderOrder = 4;
	entities.wheel3.renderOrder = 4;
	entities.wheel4.renderOrder = 4;

	entities.camera = [];

	entities.camera.rotation = new Object3D();
	entities.camera.rotation.name = "camerarotation";

	entities.camera.position = new Object3D();
	entities.camera.position.name = "cameraposition";

	entities.machine = new Object3D();
	entities.machine.name = "machine";

	entities.camera.position.add(camera);
	entities.camera.rotation.add(entities.camera.position);

	entities.machine.add(entities.car);
	entities.machine.add(entities.engine);
	entities.machine.add(entities.bumper);
	entities.machine.add(entities.wheel1);
	entities.machine.add(entities.wheel2);
	entities.machine.add(entities.wheel3);
	entities.machine.add(entities.wheel4);

	entities.scene = new Object3D();
	entities.scene.name = "scene";
	entities.scene.frustumCulled = false;

	entities.scene.add(entities.machine);
	entities.scene.add(entities.Cylinder114);
	entities.scene.add(entities.camera.rotation);

	for (let entity in entities) {
		if(entity != "scene" && entity != "camera" && entity != "machine") {
			entities[entity].castShadow = true;
			entities[entity].receiveShadow = true;
			entities[entity].frustumCulled = false;
		}
	}

	scene.add(entities.scene);
	scene.fog = new Fog("#213570", 400, 2500 );
}
