import { PMREMGenerator, ACESFilmicToneMapping, WebGLCubeRenderTarget, RGBFormat, LinearMipmapLinearFilter, CubeCamera, Color } from "three/build/three.module";

const MOBILE = /iphone|ipod|ipad|android|blackberry|opera mini|opera mobi|skyfire|maemo|windows phone|palm|iemobile|symbian|symbianos|fennec/i.test(navigator.userAgent.toLowerCase());

export function setApp(App) {
	//SETTINGS ENVIRONMENT
	if(!MOBILE) {
		let pmremGenerator = new PMREMGenerator( App.renderer );
		pmremGenerator.compileEquirectangularShader();
		let pngCubeRenderTarget =
			pmremGenerator.fromEquirectangular( App.assets.textures.environment );
		let pngBackground = pngCubeRenderTarget.texture;
		pmremGenerator.dispose();

		App.scene.environment = pngBackground;
	
		// App.scene.background = App.assets.textures.background;
		//SETTINGS TONE
		App.renderer.toneMapping = ACESFilmicToneMapping;
		App.renderer.toneMappingExposure = 0.8;
	}
	//SETTINGS CONTROLS RANGE
	// App.controls.enabled = false;
	// App.controls.minDistance = 150;
	// App.controls.maxDistance = 500;
	// App.controls.maxPolarAngle = Math.PI / 2;
	// App.controls.enableKeys = false;
	//AUTO ROTATE CONTROLS
	// App.controls.enableDamping = true;
	// App.controls.dampingFactor = 0.25;
	// App.controls.autoRotate = true;
	// App.controls.autoRotateSpeed = 0.5;
	//DEFAULT OBJECTS POSITION
	App.entities.wheel1.rotation.x = 1.24;
	App.entities.wheel2.rotation.x = 1.98;
	App.entities.wheel3.rotation.x = 2.43;
	App.entities.wheel4.rotation.x = 3.67;
	//DEFAULT ANIMATE POSITION
	App.entities.machine.position.set(0, 0, 500);
	App.entities.camera.rotation.rotation.y = Math.PI / 2;
	App.entities.camera.position.position.y = 40;
	App.entities.camera.position.rotation.x = 0.13;
	App.entities.Cylinder114.material.opacity = 0;
}
