import { AmbientLight, SpotLight, PointLight, Object3D, SpotLightHelper } from "three/build/three.module";

const MOBILE = /iphone|ipod|ipad|android|blackberry|opera mini|opera mobi|skyfire|maemo|windows phone|palm|iemobile|symbian|symbianos|fennec/i.test(navigator.userAgent.toLowerCase());

export function addLights(scene) {
	let lights = [];
	//**************************************************//
	//****************** MAIN LIGHT ********************//
	//**************************************************//
  const mainLight = new SpotLight(0xffffff, 1.0);
	mainLight.position.set(0, 300, 300);
	mainLight.distance = 800;
	mainLight.angle = 0.4;
	mainLight.penumbra = 0.6;
	if(!MOBILE) mainLight.castShadow = true;
  if(!MOBILE) mainLight.shadow.mapSize.width = 512;
  if(!MOBILE) mainLight.shadow.mapSize.height = 512;

  scene.add(mainLight);
	//scene.add(new SpotLightHelper( mainLight, 10 ));
	//**************************************************//
	//****************** LIGHT №1 **********************//
	//**************************************************//
	const advancedLight_1 = new SpotLight(0xffffff, 0.5);
	advancedLight_1.position.set(400, 0, 200);
	advancedLight_1.distance = 800;
	advancedLight_1.angle = 0.4;
	advancedLight_1.penumbra = 0.6;

	if(!MOBILE) scene.add(advancedLight_1);
	//scene.add(new SpotLightHelper( advancedLight_1, 10 ));
	//**************************************************//
	//****************** LIGHT №2 **********************//
	//**************************************************//
	const advancedLight_2 = new SpotLight(0xffffff, 0.2);
	advancedLight_2.position.set(-400, 0, 0);
	advancedLight_2.distance = 1000;
	advancedLight_2.angle = 1.0;
	advancedLight_2.penumbra = 1.0;

	//scene.add(advancedLight_2);
	//scene.add(new SpotLightHelper( advancedLight_2, 40 ));
	//**************************************************//
	//****************** LIGHT №3 **********************//
	//**************************************************//
	const targetForadvancedLight_3 = new Object3D();
	targetForadvancedLight_3.position.set(-600, 0, 0);
	if(!MOBILE) scene.add(targetForadvancedLight_3);

	const advancedLight_3 = new SpotLight(0xffffff, 1.0);
	advancedLight_3.position.set(-300, 400, 0);
	advancedLight_3.distance = 800;
	advancedLight_3.angle = 0.4;
	advancedLight_3.penumbra = 0.6;
	advancedLight_3.target = targetForadvancedLight_3;

	//scene.add(advancedLight_3);
	//scene.add(new SpotLightHelper( advancedLight_3, 100 ));
	//**************************************************//
	//*******************  ENDED  **********************//
	//**************************************************//
	const targetEndedLight_1 = new Object3D();
	targetEndedLight_1.position.set(0, 0, -500);
	scene.add(targetEndedLight_1);

	const endedLight_1 = new SpotLight(0xffffff, 2.0, 600, 0.3, 1, 2);
	endedLight_1.position.set(0, 200, -500);
	endedLight_1.target = targetEndedLight_1;
	lights.endedLight_1 = endedLight_1;
	if(!MOBILE) scene.add(endedLight_1);
	// scene.add(new SpotLightHelper( endedLight_1, 10 ));

	const targetEndedLight_2 = new Object3D();
	targetEndedLight_2.position.set(0, 0, -1000);
	if(!MOBILE) scene.add(targetEndedLight_2);

	const endedLight_2 = new SpotLight(0xffffff, 2.0, 600, 0.3, 1, 2);
	endedLight_2.position.set(0, 200, -1000);
	endedLight_2.target = targetEndedLight_2;
	lights.endedLight_2 = endedLight_2;
	if(!MOBILE) scene.add(endedLight_2);
	// scene.add(new SpotLightHelper( endedLight_2, 10 ));

	const targetEndedLight_3 = new Object3D();
	targetEndedLight_3.position.set(0, 0, -1500);
	if(!MOBILE) scene.add(targetEndedLight_3);

	const endedLight_3 = new SpotLight(0xffffff, 2.0, 600, 0.3, 1, 2);
	endedLight_3.position.set(0, 200, -1500);
	endedLight_3.target = targetEndedLight_3;
	lights.endedLight_3 = endedLight_3;
	if(!MOBILE) scene.add(endedLight_3);
	// scene.add(new SpotLightHelper( endedLight_3, 10 ));
	//**************************************************//
	//****************** POINT LIGHT *******************//
	//**************************************************//
	const pointLight_1 = new PointLight(0xA65EF2, 1.0, 1200, 2);
	pointLight_1.position.set(100, 600, 0);
	lights.pointLight_1 = pointLight_1;
	if(!MOBILE) scene.add( pointLight_1 );

	const pointLight_2 = new PointLight(0xA65EF2, 1.0, 1200, 2);
	pointLight_2.position.set(-100, 600, 0);
	lights.pointLight_2 = pointLight_2;
	if(!MOBILE) scene.add( pointLight_2 );

	//**************************************************//
	//****************** AMBIENT LIGHT *****************//
	//**************************************************//
	const ambientLight = new AmbientLight(0xffffff, 0.1);
  scene.add(ambientLight);

	return lights;
}
