import { Scene, Clock, WebGLRenderer, PCFSoftShadowMap, BasicShadowMap, GammaEncoding, PerspectiveCamera } from "three/build/three.module";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";

const MOBILE = window.isMobile = /iphone|ipod|ipad|android|blackberry|opera mini|opera mobi|skyfire|maemo|windows phone|palm|iemobile|symbian|symbianos|fennec/i.test(navigator.userAgent.toLowerCase());

export default class WebGLApp {
  constructor(options) {
    this.onLoad    = (typeof(options.onLoad) == "function") ? options.onLoad : () => {};
    this.onAnimate = (typeof(options.onAnimate) == "function") ? options.onAnimate : () => {};

    this.isLoaded = false;
    this.isReady = false;
		this.isMobile = MOBILE;

    this.timer = (options.timer) ? new Clock() : null;

    this.width  = window.innerWidth;
    this.height = window.innerHeight;
    //SCENE
    this.scene = new Scene();
    //RENDERER
    this.renderer = new WebGLRenderer({
      antialias: true,
      alpha: true
    });
    this.renderer.setClearColor(0xFFFFFF, 0);
    this.renderer.setPixelRatio(window.devicePixelRatio);
    this.renderer.setSize(this.width, this.height);
		this.renderer.shadowMap.enabled = !this.isMobile;
		this.renderer.shadowMap.type = (this.isMobile) ? BasicShadowMap : PCFSoftShadowMap;
		//this.renderer.shadowMap.type = BasicShadowMap;
		this.renderer.outputEncoding = GammaEncoding;
    //CONTAINER
    this.container = document.getElementById(options.container);
    this.container.appendChild(this.renderer.domElement);
    //CAMERA
    this.camera = new PerspectiveCamera(
      (options.fov) ? options.fov : 50,
      this.width / this.height,
      (options.near) ? options.near : 0.01,
      (options.far) ? options.far : 1000
    );
    this.camera.position.set(
      (options.x) ? options.x : 0,
      (options.y) ? options.y : 0,
      (options.z) ? options.z : 500
    );
		this.camera.rotation.set(
      (options._x) ? options._x : 0,
      (options._y) ? options._y : 0,
      (options._z) ? options._z : 0
    );
    this.camera.lookAt(this.scene.position);
    //CONTROLS
    this.controls = (options.controls) ?
      new OrbitControls( this.camera, this.renderer.domElement ) : null;

    this.listeners();
    this.resize();
    this.ready();
  }

  listeners() {
    window.addEventListener("resize", this.resize.bind(this));
  }

  resize() {
    this.width = window.innerWidth;
    this.height = window.innerHeight;
		this.renderer.setSize(this.width, this.height);
		this.camera.aspect = this.width / this.height;
		if(this.width < 940) {
			this.camera.position.z = 550;
			this.camera.position.y = 70;
		}
		else {
			this.camera.position.z = 310;
			this.camera.position.y = 0;
		}

    this.vheight = this.camera.position.z * Math.tan(this.camera.fov / 2 * (Math.PI / 180));
    this.vwidth = this.vheight * this.camera.aspect;

    this.camera.updateProjectionMatrix();
  }

  ready() {
    this.isLoaded = true;

    this.onLoad(this);
    this.animate();
  }

  animate() {
    this.render();

    if (this.isLoaded)
      this.onAnimate(this.timer);

    requestAnimationFrame(this.animate.bind(this));
  }

  render() {
    this.renderer.render(this.scene, this.camera);
  }
}
