import { WEBGL } from "three/examples/jsm/WebGL";

import WebGLApp from "./umiks/WebGLApp";

import { addAssets } from "./umiks/process/addAssets";
import { addLights } from "./umiks/process/addLights";
import { addAnimations } from "./umiks/process/addAnimations";

import { setAssets } from "./umiks/process/setAssets";
import { setScene } from "./umiks/process/setScene";
import { setApp } from "./umiks/process/setApp";

const IS_WEBGL = WEBGL.isWebGLAvailable();

document.addEventListener("mousemove", mousemoveEvent, false);

let flagAnimation = true;
let flagRotateWheels = false;
let wheelRotationSpeedEnding = 0.5;
let wheelRotationSpeedCurrent = 0;
let Mouse = {x: 0, y: 0};

let App = (IS_WEBGL) ? new WebGLApp({
	container: "threescene",
	timer: true,
	fov: 40, near: 0.01, far: 2000,
	x: 0, y: 0, z: 310,
	_x: 0, _y: 0, _z: 0,
	onLoad: appLoaded,
	onAnimate: appAnimate
}) : null;

function mousemoveEvent(event) {
	Mouse.x = (event.clientX - (App.width / 2)) / App.width;
	Mouse.y = (event.clientY - (App.height / 2)) / App.height;
}

function appLoaded(App) {
	App.assets = addAssets(appAssetsLoaded, appAssetsProgress);
}

function appAssetsProgress(url, itemsLoaded, itemsTotal) {
	let percent = Math.floor(itemsLoaded / itemsTotal * 10) / 10;
	$(".site-loader__side span").css("opacity", percent);
}

function appAssetsLoaded() {
	App.entities = setAssets(App);
	App.lights = addLights(App.scene);

	setScene(App.scene, App.entities, App.camera);
	setApp(App);

	App.animations = addAnimations(App, beginTransition, endTransition);

	App.isReady = true;

	$(".fullpage__slider").on("onLeave", function(origin, destination, direction){
		if(flagAnimation) {
			if(direction > destination) appPlayAnimation(true);
			if(direction < destination) appPlayAnimation(false);
		}
	});

	$(document).on("send", "._event__last-transition", function(){
		flagAnimation = false;
		appPlayAnimation(true);
	});

	$(".wrapper").addClass("loaded");

	setTimeout(function() {
		appPlayAnimation(true);
	}, 1000);
}

function appPlayAnimation(direction) {
	if(App == undefined || App == null || !App.isReady) return false;

	if (direction) App.animations.transitionNext();
	else App.animations.transitionPrev();
}

function beginTransition(currentSlide) {
	$(".fullpage__slider").trigger("beginTransition");
	flagRotateWheels = false;
	wheelRotationSpeedCurrent = 0;
}

function endTransition(currentSlide) {
	if (currentSlide == 4) flagRotateWheels = true;
	$(".fullpage__slider").trigger("endTransition");
}

function appAnimate(timer) {
	if(App == undefined || App == null || !App.isReady) return false;
	//LIGHTS ANIMATE
	if(!App.isMobile) {
		App.lights.pointLight_1.position.z =
			7000 * Math.sin(timer.getElapsedTime() / 3);
		App.lights.pointLight_2.position.z =
			7000 * Math.sin(-timer.getElapsedTime() / 3);
		//MOUSE
		App.entities.machine.rotation.y = -Mouse.x / 8;
		App.entities.Cylinder114.rotation.z = Mouse.x / 8;

		//WHEELS ANIMATE
		if(flagRotateWheels) {
			App.entities.wheel1.rotation.x -= wheelRotationSpeedCurrent;
			App.entities.wheel2.rotation.x -= wheelRotationSpeedCurrent;
			App.entities.wheel3.rotation.x -= wheelRotationSpeedCurrent;
			App.entities.wheel4.rotation.x -= wheelRotationSpeedCurrent;
			if(wheelRotationSpeedCurrent < wheelRotationSpeedEnding)
				wheelRotationSpeedCurrent += 0.005;
		}
	}
}
